import React from 'react'
import { graphql } from 'gatsby'

import styled from 'styled-components';

import Layout from '../../../components/Layout'
import Categories from '../../../components/Categories'
import { StaticImage } from 'gatsby-plugin-image';

            
const WhereToEarWraper = styled.div`
  text-align: center;
  margin: 0 auto;
  height: auto;
  width: 80vw;  

  h1 {
    font-size: 70px;
  }  

  @media screen and (max-width: 960px) {
    h1 {
      font-size: 40px;
      font-weight: 900;
    }
    h2 {
      font-size: 30px;      
      font-weight: 900;
      margin-bottom: 20px;
    }
  }

`

const WhereToEat = ({ data }) => {
  return (
    <Layout section="travel" pageTitle="Travel">
      <WhereToEarWraper>      
        <h1>What To Drink</h1>
        <h2>in Buenos Aires</h2>  
        <StaticImage src='../../../images/wheretodrink.jpg' height="800" width="800" />
        <h1>Bars and Cafes</h1>
        <Categories data={data} columns="3" />
      </WhereToEarWraper>
    </Layout>
  )
}

export const query = graphql`
  query {
    sitePage {
	    path
    }    
    allMdx(filter: { frontmatter: { category : { eq: "wheretodrink" } } }, sort: {fields: frontmatter___date, order: DESC}) {
      nodes {
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          image_alt
          category
          image {
            childImageSharp {
              gatsbyImageData(width: 500)
            }
          }          
        }    
        id
        excerpt(pruneLength: 75)
        slug
      }
    }
  }
`



export default WhereToEat